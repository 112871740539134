<template>
  <div>
    <v-navigation-drawer
      color="white"
      class="app-menu"
      v-model="$store.state.menu"
      app
      :right="rtl"
      width="200"
    >
      <v-list nav dense class="pt-10 list d-done">
        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/Stores"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >المحلات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->


        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/a_orders"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الطلبات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/sections"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الاقسام</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/deliveryManager"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >مديري الديلفيري</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/deliveries"
          link
        >
          <v-list-item-title class="black--text font-weight-bold">
            الديلفيري</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/countries"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الدول</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->


        
        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/cities"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >المدن</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->


        

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/languages"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >اللغات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 1"
          class="white--text"
          to="/static_trans"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الترجمة</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('orders')
          "
          class="white--text"
          to="/live"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >مباشر</v-list-item-title
          >

          <v-list-item-action>
            <v-icon color="red">live_tv</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!--/ list item -->
        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('orders')
          "
          class="white--text"
          to="/orders"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الطلبات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('orders')
          "
          class="white--text"
          to="/cube"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >مكعب</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('tables')
          "
          class="white--text"
          to="/tables"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الطاولات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('orders')
          "
          class="white--text"
          to="/Stats"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >احصائيات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 0"
          class="white--text"
          to="/Categories"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الأقسام</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->
        <v-list-item
          v-if="$store.state.user_menu_dashboard.is_admin == 0"
          class="white--text"
          to="/Products"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >المنتجات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('notes')
          "
          class="white--text"
          to="/notes"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الملاحظات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item
          v-if="
            $store.state.user_menu_dashboard.is_admin == 0 &&
            $store.getters.permissions.includes('additions')
          "
          class="white--text"
          to="/additions"
          link
        >
          <v-list-item-title class="black--text font-weight-bold"
            >الاضافات</v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->
      </v-list>

      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
    keys() {
      return this.$store.getters.lang("menu");
    },
  },
};
</script>
